import { default as SuccessIcon } from '@mui/icons-material/CheckCircleRounded';
import { PasswordlessLogin } from '@verifime/cognito-passwordless-auth';
import { useRouter } from 'next/router';
import { Landing } from '@verifime/components';

export default function LoginPage() {
  const router = useRouter();

  const signInRedirect = () => {
    router.push('/admin');
  };

  return (
    <Landing title="Admin Portal" description="*For internal use only">
      <PasswordlessLogin signInRedirect={signInRedirect}>
        <SuccessIcon color="success" />
      </PasswordlessLogin>
    </Landing>
  );
}
